import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'

const OurAndroid = () => {

    return (
      <section className="android pt-100 mb-0">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-left leftpad">
            <h3 className="heading-h2"><span className="heading-h3">Our <span className="bluecolor">Android & <span className="transNone">i</span>OS App</span></span> For Localbitcoins Clone App Development</h3>
            <p className="pharagraph head">Launch an astonishing p2p ads-based cryptocurrency exchange mobile app that functions similar to Localbitcoins.
            </p>
          </div>

          <div className="row table-content mobilewhitebox orderflex">
            <div className="col-md-12 col-lg-7 order2 mb-0">
              <p className="pharagraph" >As we can see many crypto users are using mobile apps for trading cryptocurrencies. So creating a p2p crypto trading mobile application like Localbitcoins will help you to expand your business easily and attract many crypto users from various countries. Coinsclone offers the world-class Localbitcoins clone app development service for all startups and entrepreneurs with enabling the latest features at an affordable cost. By using our service, you can create a fast, secure, and reliable cryptocurrency trading mobile app like LocalBitcoins within a few days.
              </p>
              <p className="pharagraph" >Our Localbitcoins clone app enables your users to post ads and perform trading in a hassle-free manner. Our clone app is compatible with both Android and IOS mobile devices which is an added advantage for increasing the user count in your platform.
              </p>
              <div className='mobile-hide'>
              <ButtonComponent />
              </div>
            </div>
            <div className="col-md-12 col-lg-5 mobileappicon text-center order1">
              <img width="530px" height="650px" src="https://coinsclone.mo.cloudinary.net/images/localbit/localbitcoins-clone-app-development.png" alt="Localbitcoins Clone App Development image1" />
            </div>
          </div>

          <div className="inner-container">
            <div className="platform">
              <div className="t-black plat-dev">Platforms Developed</div>
              <div className="playstoreicon">
                <div className="play">
                  <img src="https://coinsclone.mo.cloudinary.net/images/android.svg" alt="Android" />
                  <span>Android</span> </div>
                <div className="play">
                  <img src="https://coinsclone.mo.cloudinary.net/images/apple.svg" alt="Apple" />
                  <span><span className="transNone">i</span>os</span> </div>
              </div>
            </div>
          </div>
          <div className='mobile-on'>
              <ButtonComponent />
              </div>
        </div>
      </section>
    )
}

export default OurAndroid